:root {
  --fe-primary: #8338ec;
  --fe-gray: #181915;
  --fe-light: #192328;
  --fe-blue: #1271e11a;
  --fe-dark-blue: #222953;
  --fe-light-blue: #65bfed;
  --fe-blue-shadow: #0c1f3a;
}

body {
  margin: 0;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-image: url("./assets/bg/main-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden !important;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: -1;
}

@media screen and (max-height: 850px) {
  body {
    height: 100%;
    overflow-y: scroll;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiTooltip-tooltip {
  margin-top: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
